<template>
	<v-card :loading="loadingParent" max-width="800" class="mx-auto my-2" rounded="xl">
		<v-card-title>
			<span>{{ $t('courses.description') }}</span>
		</v-card-title>
		<v-card-text style="white-space: pre-line">{{ event ? event.info.description : '' }}</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'EventDescription',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	computed: {
		...mapGetters({
			event: 'events/event'
		})
	}
}
</script>
